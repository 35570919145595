import styles from './css/DPIcon.module.scss';

const IconPath = ({ index }: { readonly index: number }) => {
  if(styles['path' + index]) return (
    <span className={`${styles['path' + index]} outline-none outline-0 outline-transparent`}></span>
  );
};

export default function DPIcon({ icon, children, ...props }: {
  readonly icon: string,
  readonly children?: any,
  readonly [key: string]: any
}) {

  let inner = children;
  if(!inner) {
    let range = Array(9).fill(0).map((_: 0, i: number) => i + 1);

    inner = range.map((item: number) => <IconPath index={item} key={item - 1} />);
  }

  return (
    <span { ...props } className={`${props.className ?? ''} ${styles.dp} ${styles['dp-' + icon]} outline-none outline-0 outline-transparent`}>
      { inner }
    </span>
  );
}
