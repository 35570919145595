"use-client";

import Image from "next/image";
import { useEffect, useState } from "react";

export default function ImageWithFallback({ alt, src, fallback, ...props }: {
  readonly alt: string,
  readonly src: string,
  readonly fallback: string,
}|Record<string,any>) {
  const [err, setErr] = useState(false);

  useEffect(() => {
    setErr(false);
  }, [src]);

  return (
    <Image
      alt={alt}
      onError={() => setErr(true)}
      src={err ? fallback : src}
      {...props}
    />
  );
}
