const texts = require('./free-texts.json');

export default function _e(item: string, options?: Record<string,string|number>): string {
  item = texts[item] ?? item;
  if (options) {
    for (const key in options) {
      let val = options[key];
      if(typeof val !== 'string') val = val?.toString() ?? '';
      item = item.replace(`:${key}`, val);
    }
  }
  
  return item;
}