"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";
import "./css/loader.scss";

const toggleLoader = (show: boolean) => {
  const loader = document?.getElementById('common-page-loader-cover');
  if(loader) {
    if(show) {
      loader.style.setProperty('display', 'block', 'important');
      setTimeout(() => {
        loader.style.setProperty('opacity', '1', 'important');
        loader.style.setProperty('backdrop-filter', 'blur(1px)', 'important');
      }, 10);
    } else {
      loader.style.setProperty('opacity', '0', 'important');
      setTimeout(() => {
        loader.style.setProperty('display', 'none', 'important');
        loader.style.removeProperty('backdrop-filter');
      }, 300);
    }
  }
};

export const showLoader = () => toggleLoader(true);

export const hideLoader = () => toggleLoader(false);

export default function CommonLoader({}: {
}) {
  const pathname = usePathname();
  
  useEffect(() => {
    hideLoader();
  }, [ pathname ]);

  return (
    <div
      id="common-page-loader-cover"
      className="__commonLoader transition-all duration-300 bg-[#f9f9f933] fixed top-0 right-0 bottom-0 left-0 w-full h-full z-[999999]"
      style={{
        display: 'none',
        opacity: 0,
      }}
    />
  );
}
