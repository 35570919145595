"use client";

import Link from "next/link";
import { showLoader } from "../CommonLoader/CommonLoader";
import { usePathname } from "next/navigation";

export default function LoadingLink({ children, href, onClick, bypass, ...props}: {
  readonly children?: any,
  readonly href: string,
  readonly bypass?: boolean,
} | any) {
  const pathname = usePathname();
  const isOuter = href.includes('://');

  const handleClick = (e: any) => {
    if(
      (process.env.NEXT_PUBLIC_SHOW_LOADER_ON_LINKS ?? 'false') === 'true'
      && !isOuter
      && e
      && !e.altKey
      && !e.ctrlKey
      && !e.shiftKey
      && !e.metaKey
      && (!props?.target || props?.target === '_self')
      && !bypass
      && pathname !== (href.split('?')[0]).split('#')[0]
    ) showLoader();
    
    if(onClick) onClick();
  };

  return (
    <>
      <Link href={href} {...props} onClick={handleClick}>
        {children ?? ''}
      </Link>
    </>
  );
}
