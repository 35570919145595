import DOMPurify from "isomorphic-dompurify";
import _e from "../texts";

export const formatNumber = (val: string|number|null|undefined, isCurrency?: boolean): string => {
  if(!val) return '';
  if(parseFloat(val?.toString())?.toString() !== val?.toString()) return val.toString();

  if(parseInt(val?.toString() ?? '0') === 0) return val?.toString();
  
  val = parseFloat(val?.toString()).toString();
  
  if(isCurrency) return new Intl.NumberFormat("tr-TR", { style: 'currency', currency: 'TRY' }).format(parseFloat(val));

  return new Intl.NumberFormat("tr-TR").format(parseInt(val));
};

export const formatCurrency = (val: string|number|null|undefined): string => formatNumber(val, true);

export const formatSlug = (val: string, prefix: string|null|undefined): string => {
  let suffix = '.html';
  let dummy: string|string[] = '/' + (prefix ?? '') + '/' + val;
  dummy = dummy.split(suffix);
  
  if(dummy[dummy.length - 1] !== '') suffix = '';
  
  dummy = dummy.join('') + suffix;

  /* Older browsers may not support replaceAll method */
  if(dummy?.replaceAll) return dummy.replaceAll('//', '/');

  return dummy?.replace(/\/\//g,"/");
};

export const formatUrl = (url: string, startsWithSlash?: boolean): string => {
  if(url.split('://').length > 1) return url;
  if(startsWithSlash) {
    if(url.substring(0, 1) !== '/') url = '/' + url;
  } else {
    if(url.substring(0, 1) === '/') url = url.substring(1);
  }

  return url;
};

export const formatDate = (d?: string): string => {
  if(!d) return '';

  let date: Date|undefined;
  try {
    date = new Date(d);
  } catch(e) {
    return '';
  }

  if(!date) return '';
  
  return new Intl.DateTimeFormat('tr-TR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);
};

export const formatDateDiff = (d?: string, formedOnly?: boolean) => {
  if(!d) {
    if(formedOnly) return '';
    return { days: 0, weeks: 0, months: 0, years: 0, formed: '' };
  }

  const now = new Date();
  const then = new Date(d);

  const fullDiff = now.getTime() - then.getTime();
  const answer = {
    days: Math.floor(fullDiff / (24 * 3600 * 1000)),
    weeks: Math.floor(fullDiff / (24 * 3600 * 1000 * 7)),
    months: (now.getMonth() + (12 * now.getFullYear())) - (then.getMonth() + (12 * then.getFullYear())),
  } as Record<string, any>;
  
  answer.years = Math.floor(answer.months / 12);

  if(answer.days === 0) {
    answer.formed = _e('Bugün');
  } else if(answer.days === 1) {
    answer.formed = _e('Dün');
  } else if(answer.days < 7) {
    answer.formed = _e(':count gün önce', { count: answer.days });
  } else if(answer.months === 0) {
    answer.formed = _e(':count hafta önce', { count: answer.weeks });
  } else if(answer.months < 12) {
    answer.formed = _e(':count ay önce', { count: answer.months });
  } else {
    answer.formed = _e(':count yıl önce', { count: answer.years });
  }

  return formedOnly ? answer.formed : answer;
};

export const flattenData = (data: Record<string,any>, recursive?: boolean): Record<string,any> => {
  if(Array.isArray(data)) return data.map((item: Record<string,any>) => flattenData(item, recursive));

  let d = { ...data };
  
  if(d?.attributes) {
    const attributes = d.attributes;
    delete d.attributes;
    
    d = { ...d, ...attributes };
  }

  if(recursive) Object.keys(d).forEach((key: string) => {
    if(typeof d[key] === 'object' && d[key] !== null) {
      if(d[key]?.attributes) {
        d[key] = flattenData(d[key], recursive);
      } else if(d[key]?.data) {
        d[key] = flattenData(d[key].data, recursive);
      } else if(Array.isArray(d[key])) {
        d[key] = flattenData(d[key], recursive);
      }
    }
  });

  return d;
};

export const formatPackageEventItem = (pkg: Record<string,any>, category?: string): Record<string,any> => {
  return {
    "id": getECommerceId(pkg),
    "name": pkg.caption,
    "price": pkg.price,
    "brand": 'Doping Hafıza',
    "category": category ? category : pkg?.package_category?.caption ?? '',
  };
};

export const getECommerceId = (pkg: Record<string,any>): string => {
  if((process.env.NEXT_PUBLIC_USE_ECOMMERCE_ID ?? 'false') === 'true' || !pkg?.package_id) {
    const season = pkg?.season ?? pkg?.package_category?.season;
    const ecommerce_id = pkg.ecommerce_id;
    const ecommerce_category_id = pkg?.package_category?.ecommerce_id;
    
    if(ecommerce_id) return (ecommerce_category_id ? ecommerce_category_id + '-' : '') + ecommerce_id + (season ? '-' + season : '');
  }
  
  return String(pkg?.package_id ?? '');
};

export const formatENetValues = (data: Record<string,any>): Record<string,any> => {
  const texts = {
    'Çözücü': 'Çözücü uygulamasında :count soru sorma hakkı',
    'Şimdi Anladım': 'Şimdi Anladım uygulamasında :meeting_count Express Özel Ders hakkı',
    'Koçum Yanımda': 'Koçum Yanımda uygulamasında :interview_count görüşme + :message_count mesaj hakkı',
    'Online Eğitim': 'Yapay Zeka Destekli Akıllı Test',
    'Sezon': ':year',
    'Paket Adı': ':name',
  };

  const enet_values: Record<string,any> = {
    'Çözücü': {
      'count': Object.keys(data).includes('SOLVER') ? data.SOLVER?.details?.SOLVE_QUESTION : null,
    },
    'Şimdi Anladım': {
      'meeting_count': Object.keys(data).includes('GOT_IT') ? data.GOT_IT?.details?.EXAM_COACH_MEETING : null,
    },
    'Koçum Yanımda': {
      'interview_count': Object.keys(data).includes('COACH') ? (data.COACH?.details?.EXAM_COACH_MEETING ?? 0) + (data.COACH?.details?.UNI_COACH_MEETING ?? 0) : null,
      'message_count': Object.keys(data).includes('COACH') ? data.COACH?.details?.MESSAGE : null,
    },
    'Online Eğitim': {
      'count': Object.keys(data).includes('ONLINE_EDUCATION') ? true : false,
    },
    'Sezon': {
      'year': data?.Sezon ? data.Sezon : null,
    },
    'Paket Adı': {
      'name': data?.['Paket Adı'] ? data['Paket Adı'] : null,
    },
  };

  enet_values['Çözücü'].available = enet_values['Çözücü'].count ? _e(texts['Çözücü'], { 'count': enet_values['Çözücü'].count }) : null;
  enet_values['Şimdi Anladım'].available = enet_values['Şimdi Anladım'].meeting_count ? `${_e(texts['Şimdi Anladım'], { 'meeting_count': enet_values['Şimdi Anladım'].meeting_count})}` : null;
  enet_values['Koçum Yanımda'].available = enet_values['Koçum Yanımda'].interview_count || enet_values['Koçum Yanımda'].message_count ? `${_e(texts['Koçum Yanımda'], {
    'interview_count': enet_values['Koçum Yanımda'].interview_count,
    'message_count': enet_values['Koçum Yanımda'].message_count
  })}` : null;
  enet_values['Online Eğitim'].available = enet_values['Online Eğitim'].count ? `${_e(texts['Online Eğitim'])}` : null;
  enet_values['Sezon'].available = enet_values['Sezon'].year ? _e(texts['Sezon'], { 'year': enet_values['Sezon'].year }) : null;
  enet_values['Paket Adı'].available = enet_values['Paket Adı'].name ? _e(texts['Paket Adı'], { 'name': enet_values['Paket Adı'].name }) : null;

  return enet_values;
};

export const insertENetValues = (text: string, values: Record<string,any>, strip?: boolean): string => {
  [
    'Çözücü',
    'Şimdi Anladım',
    'Koçum Yanımda',
    'Online Eğitim',
    'Sezon',
    'Paket Adı',
  ].forEach((item: string) => {
    text = text.split('[' + item + '/]').join(values[item]?.available ?? '');

    let text_r = text.split('[' + item + ']');
    if(text_r.length > 1) {
      const new_text = text_r?.map((inner: string) => {
        let spl = inner.split('[/' + item + ']');
        if(spl.length > 1) {
          spl[0] = values[item]?.available ? _e(spl[0], {
            'count': values[item]?.count ?? '',
            'meeting_count': values[item]?.meeting_count ?? '',
            'interview_count': values[item]?.interview_count ?? '',
            'message_count': values[item]?.message_count ?? '',
            'year': values[item]?.year ?? '',
            'name': values[item]?.name ?? ''
          }) : '';
        }
  
        return spl.join('');
      });
  
      text = new_text.join('');
    }
  });
  
  if(strip) {
    text = DOMPurify.sanitize(text ?? '', { USE_PROFILES: { html: false } });
  } else {
    text = DOMPurify.sanitize(text ?? '');
  }

  return text;
};

export const formatRSSItem = (item: Record<string,any>): string => {
  let str: string = '';
  for(let k in item) {
    str = `${str}<g:${k}>${item[k]}</g:${k}>`;
  }

  return `<item>${str}</item>`;
};

export const formatGTMPhone = (phone: string): string => {
  if(!phone) return '';

  phone = phone?.replace(/[\s\(\)]/g, "");
  phone = phone?.replace(/^\+/, "");
  phone = phone?.replace(/^9/, "");
  phone = phone?.replace(/^0/, "");

  return '+90' + phone;
};